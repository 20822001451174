.wrapper {
  @apply relative z-10;

  & :global(.swiper-slide) {
    @apply w-full;

    & figure {
      @apply aspect-w-16 aspect-h-12;
    }

    & :global(.caption) {
      @apply px-4 md:px-0 mt-2 text-sm max-w-1xl mx-auto;
    }
  }
  & :global(.button-container) {
    @apply w-full h-full absolute z-10 left-1/2;
    @apply transform -translate-y-1/2 -translate-x-1/2;
    @apply flex justify-between items-center;
    @apply px-4 lg:px-8;
    @apply top-[calc(50%-2.5rem)];
  }

  & :global(.nav-button) {
    @apply w-10 h-10 lg:h-13 lg:w-13 bg-white rounded-full flex justify-center items-center;

    & svg {
      @apply w-5.5 h-auto lg:w-6;
    }

    &:global(.prev) {
      @apply transform rotate-180;
    }

    &:global(.is-dim) {
      @apply opacity-50 cursor-default pointer-events-none;
    }
  }

  &:global(.small) {
    & :global(.swiper-slide) {
      @apply max-w-1xl;
    }

    & :global(.button-container) {
      @apply max-w-1xl;
    }
  }

  &:global(.column) {
    & :global(.swiper-slide) {
      @apply max-w-4xl;
    }

    & :global(.button-container) {
      @apply max-w-4xl;
    }
  }

  &:global(.large) {
    & :global(.swiper-slide) {
      @apply max-w-8xl;

      & figure {
        @apply md:aspect-w-16 md:aspect-h-9;
      }
    }

    & :global(.button-container) {
      @apply max-w-8xl;
    }
  }
}

.swiper-nav-prev {
  @apply ml-0;
}

.swiper-nav-next {
  @apply mr-0;
}
